var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-cell",
      on: { focusout: _vm.onFocusOut, keydown: _vm.onKeyDown },
    },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          staticClass: "custom-select form-control",
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.value = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.onChange,
            ],
          },
        },
        [
          _vm._l(_vm.options, function (opt, index) {
            return [
              _c("option", { key: index, domProps: { value: opt.value } }, [
                _vm._v(_vm._s(opt.text)),
              ]),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }