<template>
    <div class="edit-cell" @focusout="onFocusOut" @keydown="onKeyDown">
      <select class="custom-select form-control" v-model="value" @change="onChange">
        <template v-for="(opt, index) in options">
          <option :value="opt.value" :key="index">{{ opt.text }}</option>
        </template>
      </select>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import { getAppendAfterTopDownRelationship } from '@/components/modal/script/field';
  
  const KEY_BACKSPACE = 'Backspace';
  const KEY_DELETE = 'Delete';
  const KEYCODE_ENTER = 13;
  const KEYCODE_TAB = 9;
  const KEYCODE_ESCAPE = 27;
  
  export default Vue.extend({
    name: 'AppendAfterEditor',
    data() {
      return {
        value: '',
        cancelBeforeStart: false,
        customProp: null,
        options: []
      };
    },
    methods: {
      getValue() {
        return this.value;
      },
      isPopup() {
        return false;
      },
      isCancelBeforeStart() {
        return this.cancelBeforeStart;
      },
  
      setInitialState(params) {
        let startValue;
        if (params.key === KEY_BACKSPACE || params.key === KEY_DELETE) {
          // if backspace or delete pressed, we clear the cell
          startValue = null;
        } else if (params.charPress) {
          // if a letter was pressed, we start with the letter
          if (this.options == null || this.options.length == 0) {
            startValue = null;
          } else {
            const found = this.options.find(i => i.text.toLowerCase().startsWith(params.charPress.toLowerCase()));
            if (found) {
              startValue = found.value;
            } else {
              startValue = this.options[0].value;
            }
          }
          
        } else {
          // otherwise we start with the current value
          startValue = this.customProp == null
            ? params.value
            : params.data[this.customProp];
        }
  
        this.value = startValue;
      },
  
      // will reject the value if condition met
      isCancelAfterEnd() {
        return false;
      },
  
      onKeyDown(event) {
        const keyCode = this.getCharCodeFromEvent(event);
        const api = this.params.api;
        if (KEYCODE_ENTER == keyCode) {
          event.stopPropagation();
          event.preventDefault();
          if (event.shiftKey) {
            this.moveUp(api, this.params.rowIndex, this.params.column.colId);
          } else {
            this.moveDown(api, this.params.rowIndex, this.params.column.colId);
          }
          return;
        } else if (KEYCODE_TAB == keyCode) {
          event.stopPropagation();
          event.preventDefault();
          if (event.shiftKey) {
            api.tabToPreviousCell();
          } else {
            api.tabToNextCell();
          }
          return;
        }
  
        if (keyCode == KEYCODE_ESCAPE || keyCode == KEYCODE_ENTER) {
          if (keyCode == KEYCODE_ESCAPE) {
            this.value = this.params.value;
          }
          setTimeout(() => {
            this.params.stopEditing();
          }, 100);
        }
      },
  
      getCharCodeFromEvent(event) {
        event = event || window.event;
        return typeof event.which === 'undefined' ? event.keyCode : event.which;
      },
  
      isCharNumeric(charStr) {
        return /\d/.test(charStr);
      },
  
      isKeyPressedNumeric(event) {
        const charStr = event.key;
        return this.isCharNumeric(charStr);
      },
  
      finishedEditingPressed(event) {
        const charCode = this.getCharCodeFromEvent(event);
        return charCode === KEYCODE_ENTER || charCode === KEYCODE_TAB;
      },
  
      deleteOrBackspace(event) {
        return (
          [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) >
          -1
        );
      },
  
      isLeftOrRight(event) {
        return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
      },
      
      onChange(val) {
        if (this.params.setLabel) {
          const item = this.params.options.filter(o => o.value === val);
          this.params.data.label = item.length !== 0 ? item[0].text : null;
        }
        
        if (this.params.onChange) {
          this.params.onChange(val, this.params);
        }
        this.params.api.stopEditing();
      },
  
      onFocusOut() {
        setTimeout(() => {
          this.params.api.stopEditing();
        }, 300)
      },
  
      moveUp(api, pRowIndex, pColId) {
        setTimeout(() => {
          let rowIndex = pRowIndex - 1;
          const colId = pColId;
          if (rowIndex < 0) {
            rowIndex = 0;
          }
          api.clearRangeSelection();
          api.setFocusedCell(rowIndex, colId, null);
          api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [colId]
          });
        })
      },
  
      moveDown(api, pRowIndex, pColId) {
        setTimeout(() => {
          let rowIndex = pRowIndex + 1;
          const colId = pColId;
          if (api.getDisplayedRowAtIndex(rowIndex) == null) {
            rowIndex = this.params.rowIndex;
          }
          api.clearRangeSelection();
          api.setFocusedCell(rowIndex, colId, null);
          api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [colId]
          });
        })
      },

      collectOptionExclusionList(map={}, startingValue, exclusionList=[]) {
        if (Object.keys(map).length == 0) {
          return;
        }
        
        const list = map[startingValue];
        if (list != null)  {
          for (const i of list) {
            exclusionList.push(i);
            if (Array.isArray(map[i]) && map[i].length > 0) {
              this.collectOptionExclusionList(map, i, exclusionList);
            }
          }
        }
        
      }
    },
  
    created() {
      const componentParent = this.params.context?.componentParent;
      if (this.params.optionProp != null && componentParent != null) {
        
        let opts = componentParent[this.params.optionProp];
        if (this.params.api != null) {
          const curName = this.params.data?.name;
          const records = [];
          this.params.api.forEachNode(node => records.push(node.data));
          const map = getAppendAfterTopDownRelationship(records);
          
          const toBeExcluded = [];
          if (curName != null) {
            toBeExcluded.push(curName)
            this.collectOptionExclusionList(map, curName, toBeExcluded);
          }
          opts = opts.filter(i => !toBeExcluded.includes(i.value));
        }

        this.options = opts;
      }
      if (this.params.customProp != null) {
        this.customProp = this.params.customProp;
      }
      this.setInitialState(this.params);
    },
    mounted() {
      
    }
  })
  </script>
  