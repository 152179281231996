<template>
    <div>
      <span>{{ label }}</span>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  
  export default Vue.extend({
    name: 'AppendAfterCellRenderer',
    data() {
      return {
        value: null,
      }
    },
    beforeMount() {
      const data = this.params && this.params.data? this.params.data : null;
      const append_after = this.params.value? this.params.value : null;
      
      const componentParent = this.params.context?.componentParent;
      if (this.params.optionProp != null && componentParent != null) {
        
        const opts = componentParent[this.params.optionProp];
        
        const found = opts.find(i => i.value == append_after);
        if (found != null) {
          this.value = found.text;
        }
      }
  
      if (this.value == null) {
        this.value = this.params.value;
      }
  
    },
    computed: {
      label() {
        return this.value? this.value : '';
      }
    }
  })
  </script>
  